.user-select__indicators {
  display: none !important;
}

.user-select__multi-value__label {
  font-size: 100% !important;
}

.fixed-schedule-preview-cell{
  max-width: 60px;
  white-space: break-spaces !important;
}