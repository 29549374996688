.rdrCalendarWrapper {
  border-radius: 0.35rem;
  .rdrDateDisplayWrapper {
    border-top-left-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: #5e72e4;
  }
  .rdrNextPrevButton {
    background: #5e72e4;
    &:hover {
      background: #5e72e4;
      opacity: 0.8;
    }
    &.rdrPprevButton i {
      border-color: transparent #fff transparent transparent;
    }
    &.rdrNextButton i {
      border-color: transparent transparent transparent #fff;
    }
  }
}


