.circle-user::after {
  display: none !important;
}

.navbar:not(.navbar-nav-hover) .user-header-dropdown.show {
  top: 100% !important;
}
.navbar-vertical .navbar-collapse:before {
  margin: .3rem !important;
}
.media:hover {
  cursor: pointer !important;
}
