.multiSelectContainer {
  .searchWrapper {
    border: 1px solid #dee2e6;
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    margin-top: 0;
  }
  .chip {
    background: #5e72e4;
  }
  .option:hover {
    background: #5e72e4;
  }
  .highlightOption {
    background: #5e72e4;
  }
}