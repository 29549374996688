.btn-view {
  width: 50;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: auto 0;
  justify-content: center;
  align-items: center;
}

.btn-next,
.btn-previous {
  padding-left: 12px;
  padding-right: 12px;
}

.duplicate-warning-tooltip {
  .tooltip-inner {
    font-size: .75rem;
    padding: 4px 8px;
    font-weight: 600;
    opacity: 0.9;
  }
}

.warning-tag {
  .tooltip-inner {
    text-align: left;
    font-size: .75rem;
    padding: 4px 8px;
    ul {
      margin-left: -1rem;
      opacity: 0.9;
      
    }
  }
}

