.react-datepicker {
  border: 0;
}
.react-datepicker__time-container {
  width: 150px;
  .react-datepicker__header {
    background: #5e72e4;
  }
  .react-datepicker-time__header {
    padding: 0;
    background: #5e72e4;
    color: #fff;
  }
  & .react-datepicker__time {
    & .react-datepicker__time-box {
      width: 150px;
      ul.react-datepicker__time-list {
        padding-left: 0.3rem;
        padding-right: 1rem;
        li.react-datepicker__time-list-item {
          // font-size: 0.85rem;
          // font-weight: 600;
          // color: #32325d;
          font-family: "Open Sans", sans-serif;
          &:hover {
            background: #5e72e4;
            color: #fff
          }
        }
        // overflow-y: hidden;
        li.react-datepicker__time-list-item--selected {
          background: #5e72e4;
          color: #fff;
          &:hover {
            background: #5e72e4;
          }
        }
      }
    }
  }
}
