.mentions {
  margin: 0.3rem 0;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 0.625rem 0.75rem;
  height: 60px;
}
.mentions--multiLine .mentions__input {
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem;
  resize: none;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #8898aa;
  border: 1px solid #dee2e6;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  font-size: 0.875rem;
  transition: all 0.15s ease-in-out;
  overflow: auto !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid #ddd;
}

.mentions__suggestions__item {
  padding: 5px 8px;
}

.mentions__suggestions__item--focused {
  background-color: var(--primary);
  color: #fff;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  background: var(--primary);
  color: #fff;
  pointer-events: none;
  padding: 0.025rem 0.05rem;
}
