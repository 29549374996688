@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import '~bootstrap/scss/bootstrap';
@import './assets/stylesheets/styles.scss';
@import 'font-awesome/css/font-awesome.min.css';
@import 'react-contexify/dist/ReactContexify.css';

.contexify_theme-light {
  --contexify-menu-bgColor: #fff;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #5e72e4de;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
  --contexify-menu-shadow: 0px 3px 4px 3px rgba(0, 0, 0, 0.1);
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.disable-cursor {
  &:hover {
    cursor: not-allowed;
  }
}
